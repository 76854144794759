import React from "react";
import { Content } from "patterns/Content";
import { ContentBlock } from "patterns/ContentBlock";
import { MastheadNewsletter } from "patterns/MastheadNewsletter";
import { Shell } from "patterns/Shell";

import "../../scss/main.scss";

function NewsletterIssue13({
    location: {
        pathname,
    },
}) {
    return (
        <Shell
            pageDescription="Business update, freelance advice and more"
            pageSocialImage=""
            pageTitle="Issue #13"
            pathname={pathname}
        >
            <MastheadNewsletter
                authorImage={{
                    alt: "",
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: "https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format"
                }}
                authorName="Rob Simpson"
                title="Issue #13"
            />
            <div className="container-large">
                <Content
                    content={[
                        {spans: [], text: "It’s been a whilst since my last newsletter, but hopefully, that’ll change going forward.", type: "paragraph"},
                        {spans: [], text: "Behind the scenes I’ve been trying to develop some systems that’ll make it easier for me to stay on top of all the content creation I do (which includes this newsletter).", type: "paragraph"},
                        {spans: [], text: "It feels like a lot has changed since my last newsletter so here goes:", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🗓 Looking back at October"
                    content={[
                        {spans: [], text: "🎒 Business update", type: "heading4"},
                        {spans: [], text: "Over the past few months, I’ve seen a downturn in the number of leads coming in, but in the past couple of weeks I’ve had a bunch of high budget projects land in my inbox 🤯.", type: "paragraph"},
                        {spans: [], text: "I’ve been posting relatively consistently on LinkedIn for the past couple of months, which may or may not be paying off.", type: "paragraph"},
                        {spans: [], text: "I see this as a bit of a long game, I’ve started approaching many things with the expectation that something isn’t just going to happen overnight and that I’ll need to stick with it consistenly before I see results.", type: "paragraph"},
                        {spans: [], text: "Back in May, I mentioned I was planning on repositioning my business to focus on working with sustainable companies.", type: "paragraph"},
                        {spans: [], text: "Well, I’ve been working towards that…", type: "paragraph"},
                        {spans: [], text: "🖥 New website WIP", type: "heading4"},
                        {spans: [], text: "I’ve been working on a new website to reflect this new reposition.", type: "paragraph"},
                        {spans: [], text: "I ended up hiring a copywriter to write content for some key pages on my website.", type: "paragraph"},
                        {spans: [], text: "I’ve also finished the design of it and after 2 years of freelancing, I’ve finally put some case studies together.", type: "paragraph"},
                        {spans: [], text: "I’m also getting a video created which is being worked on as we speak, so other than that I just need to build it.", type: "paragraph"},
                        {spans: [], text: "I’ve been using Sanity a lot over the past year and a half, with other teams, but I’m yet to do a complete project from start to end by myself – so I’m thinknig about using it myself to fill in some knowledge gaps.", type: "paragraph"},
                        {spans: [], text: "I’m also thinking of giving Astro a try, for one I want to see what it’s like and mostly it has no JavaScript runtime overhead.", type: "paragraph"},
                        {spans: [], text: "🏔 Put yourself out there", type: "heading4"},
                        {spans: [], text: "This leads me to a bit of advice.", type: "paragraph"},
                        {spans: [], text: "For years I hid inside my comfort zone, shying away from anything that made me feel uncomfortable.", type: "paragraph"},
                        {spans: [], text: "But leaving that comfort zone allowed me to become a freelancer. And things that used to fill me with fear are now a walk in the park.", type: "paragraph"},
                        {spans: [], text: "In the past month alone I’ve done a lot more fear-inducing activities:", type: "paragraph"},
                        {spans: [], text: "🎙 Took part in Prismic’s live meetup last month", type: "list-item"},
                        {spans: [], text: "🎤 Began attending a sustainable meetup and might end up working with the organiser", type: "list-item"},
                        {spans: [], text: "🎫 Got free tickets to Blue Earth Summit (from one of my clients) and made new business connections off the back of that", type: "list-item"},
                        {spans: [], text: "♻️ Becoming known online for web sustainability because of the content I’ve consistently started sharing on LinkedIn", type: "list-item"},
                        {spans: [], text: "The old me would’ve put all of this off until my website was done, but I’ve learnt everything doesn’t need to be perfect before you get started.", type: "paragraph"},
                        {spans: [], text: "You have to go out there and make it happen for yourself.", type: "paragraph"},
                        {spans: [], text: "👨‍🍳 Stryve updates", type: "heading4"},
                        {spans: [], text: "I’m not entirely sure what is new to Stryve since my last update.", type: "paragraph"},
                        {spans: [], text: "I’ve been adding content and designing new features (which I need to build).", type: "paragraph"},
                        {spans: [], text: "But something I’ve been really excited about, which I’ve just launched this week is carbon rating badges on recipes – this is all thanks to MyEmissions.", type: "paragraph"},
                        {spans: [], text: "If you visit a recipe now it’ll show you how it scores on a scale from A-E (A being the best), and the approximate emissions produce from that meal.", type: "paragraph"},
                        {spans: [], text: "Thankfully all of the meals score either an A or B (you’d find many meat-based meals scoring a D or E).", type: "paragraph"},
                        {spans: [], text: "I’ve also had a couple of prospective clients reach out to me and tell me that they really like Stryve and use it quite often which is great to hear.", type: "paragraph"},
                        {spans: [], text: "📹 Learning videography", type: "heading4"},
                        {spans: [], text: "After years of admiring people who can make videos, I’ve decided to finally make some effort to learn and create videos so I can start putting out more engaging content.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 October goals"
                    checkList={[
                        {complete: true, text: "🏔 Do something outside your comfort zone"},
                        {complete: true, text: "👨🏻‍🎨 Finish design and content for my new website"},
                        {complete: false, text: "🏋️ Build consistent exercise habit"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="🗓 Looking ahead to November"
                    content={[
                        {spans: [], text: "For the first time since I started freelancing, I have some chunky gaps in my schedule.", type: "paragraph"},
                        {spans: [], text: "I have some warm leads I need to hit up and 2 potential clients in the pipeline who I’d be very interested in working with.", type: "paragraph"},
                        {spans: [], text: "There’s a ton of stuff I need to do for my business, like build my website for one, that I need to do.", type: "paragraph"},
                        {spans: [], text: "But I’ll be spending some time trying to sign some work from the clients I want to work with otherwise I might have to fall back on some projects I’m not super excited about but we’ll see how it goes.", type: "paragraph"},
                        {spans: [], text: "I’m actually having to work a little bit now to get interesting projects.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 November goals"
                    checkList={[
                        {complete: false, text: "🏔 Do something outside your comfort zone"},
                        {complete: false, text: "📹 Post 3 posts to personal and Stryve Instagram accounts every week"},
                        {complete: false, text: "🌯 Add 6 new recipes to Stryve"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="💡 Freelance tip of the month"
                    quote="Do things that scare you a little, it’s the best way to grow and you get a sense of accomplishment like you’re moving forwards instead of falling behind"
                />
                <ContentBlock
                    title="💰 Earnings for the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/387f0e6c-0cc1-42c6-838e-179818063602_income-oct-2022.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Income: £4,425", type: "paragraph"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="📦 Product of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/c1538b07-0436-4c8e-97b5-95eec4b897c8_shoes.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Vivobarefoot shoes", type: "heading4"},
                        {spans: [], text: "Earlier this year I bought a pair of Vivobarefoot shoes. I love them so much that I bought another pair. But for good reason. My first pair are great for dry conditions but now we’re coming up to colder months, I need a pair that would be great in the wet and for all the forest walks I seem to be doing.", type: "paragraph"},
                    ]}
                    buttonText="View product"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.vivobarefoot.com/uk/magna-trail-ii-fg-mens",
                    }}
                />
                <ContentBlock
                    title="📙 Book of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/ccb408db-fc83-4b19-b3a9-287a2e7a3ec1_book-sustainable-web-design.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Sustainable web design by Tom Greenwood", type: "heading4"},
                        {spans: [], text: "I first read this book around a year and a half ago, but it feels like a good time to revisit it now that web sustainability is a core part of my business.", type: "paragraph"},
                    ]}
                    buttonText="View book"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://abookapart.com/products/sustainable-web-design",
                    }}
                />
            </div>
        </Shell>
    );
}

export default NewsletterIssue13;
